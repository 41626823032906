import * as Sentry from "@sentry/vue";
import { App } from "vue";

export const initSentry = (app: App) => {
    if (import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'beta') {
        Sentry.init({
            app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        environment: import.meta.env.VITE_ENV,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/apigql.generalfinance\.com\.br/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}
