<script lang="ts">
import { useNhostClient } from "@nhost/vue"
import { useClientStore } from "@/store/client"
import { mapState } from "pinia"
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(useClientStore, ['selectedClient'])
  },
  created() {
    const client  = useNhostClient()
    client.nhost.auth.refreshSession().then(response => {
      window.location.replace(`${import.meta.env.VITE_DATAROOM_REDIRECT_URL}${response.session?.refreshToken}&client_id=${this.selectedClient.id}`);
    });
  },
}
</script>

<template>
  <main></main>
</template>
