import { defineStore } from 'pinia'
import { ref } from 'vue'
import { gql } from '@apollo/client/core'
import { apolloClient } from '@/plugins/nhost'

export interface ClientOrganization {
  organization: {
    name: string;
  }
}

export interface ClientSegment {
  pt_br_name: string;
  en_name: string;
}

export interface Client {
  id: string;
  name: string;
  document: string;
  is_active: boolean;
  metadata: {
    [key: string]: any;
  };
}

interface UpdateActiveClientMutation {
  set_active: {
    affected_rows: number;
  };
  set_inactive: {
    affected_rows: number;
  }
}

const GET_COMPANY_QUERY = gql`
  query getClients($where: general_user_clients_available_bool_exp) {
    general_user_clients_available (where: $where) {
      id: client_id
      name
      document
      metadata
      is_active
    }
  }
`;

const UPDATE_ACTIVE_CLIENT = gql`
  mutation updateUserClient($client_id: uuid!) {
    set_active: update_general_user_client(where: {_and : [ {client_id: {_eq: $client_id}}, {active: {_eq: false}} ]}, _set: {active: true}) {
      affected_rows
    }
    set_inactive: update_general_user_client(where: {_and : [ {client_id: {_neq: $client_id}}, {active: {_eq: true}} ]}, _set: {active: false}) {
      affected_rows
    }
  }
`

export const useClientStore = defineStore('client', () => {
  const clients = ref<Client[]>([])
  const selectedClient = ref<Client | null>(null)

  async function fetchClients() {
    try {
      const { data } = await apolloClient.query<{ general_user_clients_available: Client[] }>({
        query: GET_COMPANY_QUERY
      })
      clients.value = data.general_user_clients_available
      const activeClient = data.general_user_clients_available.find(cli => cli.is_active) as Client
      const activeClientId = activeClient ? activeClient.id : data.general_user_clients_available[0].id;
      await setSelectedClient(activeClientId)
    } catch (error) {
      console.error('Error fetching clients:', error)
    }
  }

  async function setSelectedClient(clientId: string | number) {
    const client = clients.value.find(c => c.id === clientId)
    if (client) {
      try {
        const { data } = await apolloClient.mutate<UpdateActiveClientMutation>({
          mutation: UPDATE_ACTIVE_CLIENT,
          variables: {
            client_id: clientId
          }
        })
        selectedClient.value = client

        if(data?.set_active.affected_rows) window.location.reload()

      } catch (error) {
        console.error('Error fetching clients:', error)
      }
    }
  }

  return {
    clients,
    selectedClient,
    fetchClients,
    setSelectedClient
  }
})
